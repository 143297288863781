/*
const config = {
  endPoint: 'http://localhost:8000',
  imagesPath: 'http://localhost:8000/images/',
  pusher: {
    key: '0cc7b2add5ce0aec1f9b',
    cluster: 'eu',
    tls: true
  }
};
*/
// on Deploy
const config = {
  endPoint: 'https://service.restadgard-utb.se/',
  imagesPath: 'https://service.restadgard-utb.se/images/',
  pusher: {
    key: '0903e81c19b5793444ff',
    cluster: 'eu',
    tls: true
  }
};
export default config;
